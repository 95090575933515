<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import userService from '@/services/user/user'
import { toastMixins } from '@/mixins'

import MenuBar from './components/MenuBar.vue'

import CreateEditAdminForm from '../components/CreateEditAdminForm.vue'

export default {
  mixins: [toastMixins],
  components: { MenuBar, CreateEditAdminForm },
  data() {
    return {
      errMsg: null,
      isLoading: false,
      userLogonMaster: {}
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: (state) => state.constants.defaultDelayMs
    })
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      try {
        this.isLoading = true

        const userId = this.$route.params.userId
        const res = await userService.getUserLogonMaster(userId)
        const adminInfo = res.data.data

        if (adminInfo.userLevel !== '99.99') {
          this.$router.replace({
            name: 'Admin.UserLogonMaster.Edit',
            params: { userId }
          })
        }

        this.userLogonMaster = _.cloneDeep(adminInfo)
      } catch (err) {
        this.errMsg =
          err?.response?.data?.thMessage ||
          'เกิดข้อผิดพลาดในระบบการดึงข้อมูลแอดมิน'
      } finally {
        this.isLoading = false
      }
    },
    async editAdminSubmittedHandler(event) {
      const { args } = event
      const userId = this.$route.params.userId

      this.isLoading = true

      try {
        await userService.updateUserLogonMaster(userId, args)
        this.mxDisplaySuccessMessage('แก้ไขแอดมินรายเดิมสำเร็จแล้ว')
        setTimeout(() => {
          this.$router.push({
            name: 'Admin.UserLogonMasters'
          })
        }, this.defaultDelayMs)
      } catch (err) {
        this.errMsg =
          err?.response?.data?.thMessage ||
          'เกิดข้อผิดพลาดในระบบแก้ไขแอดมินรายเดิม'
      }

      this.isLoading = false
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="แก้ไขรายละเอียดแอดมิน"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <!-- edit form -->
    <create-edit-admin-form
      :modeProp="'EDIT'"
      :isLoadingProp="isLoading"
      :userLogonMasterProp="userLogonMaster"
      @editAdminSubmitted="editAdminSubmittedHandler"
    ></create-edit-admin-form>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
